import appSettings from "config/compiledConstants";

const constants = {
    apiBaseUrl: appSettings.API_BASE_URL,
    eApiBaseUrl: appSettings.E_API_BASE_URL,
    authConfig: {
        B2C_CLIENT_ID: appSettings.B2C_CLIENT_ID,        
        CACHE_LOCATION: 'sessionStorage', // enable this for IE, as sessionStorage does not work for localhost.
        EXPIRE_OFFSET_SECONDS: 1200, // 20 minutes
        B2C_SIGNIN_AUTHORITY: appSettings.B2C_SIGNIN_AUTHORITY,
        B2C_SIGNUP_AUTHORITY: appSettings.B2C_SIGNUP_AUTHORITY,
        B2C_KNOWN_AUTHORITIES: appSettings.B2C_KNOWN_AUTHORITIES,
        B2C_SCOPES: appSettings.B2C_SCOPES,        
        TOKEN_RENEWAL_OFFSET_SECONDS: appSettings.TOKEN_RENEWAL_OFFSET_SECONDS,
        ACCESSIDP_CLIENT_ID: appSettings.ACCESSIDP_CLIENT_ID,
        ACCESSIDP_AUTHORITY: appSettings.ACCESSIDP_AUTHORITY,
        ACCESSIDP_KNOWN_AUTHORITIES: appSettings.ACCESSIDP_KNOWN_AUTHORITIES,        
        ACCESSIDP_STOREAUTHSTATEINCOOKIE: appSettings.ACCESSIDP_STOREAUTHSTATEINCOOKIE,
        ACCESSIDP_SCOPES: 'openid profile email offline_access',
        OIDC_CACHE_LOCATION: 'sessionStorage',
        OIDC_REDIRECT_URI: window.location.origin + '/signin-oidc.html',
        B2C_REDIRECT_URI: window.location.origin + '/',
        OIDC_POST_LOGOUT_REDIRECT_URI: window.location.origin + '/#/signin/',
        OIDC_LOG_LEVEL: appSettings.OIDC_LOG_LEVEL,        
        OIDC_RESPONSE_TYPE: 'code',
        OIDC_RESPONSE_MODE: 'query',
    },
};

export default constants;
