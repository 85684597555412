'use strict';

import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Async from 'react-code-splitting';

import TngApp from 'components/base/TngApp';

const Home = props => <Async load={import('components/home/Home')} componentProps={props} />;
const Search = props => <Async load={import('components/search/Search')} componentProps={props} />;
const Styles = props => <Async load={import('components/base/Styles')} componentProps={props} />;
const SignOut = props => <Async load={import('components/base/SignOut')} componentProps={props} />;
const TermsAndConditions = props => <Async load={import('components/base/TermsAndConditions')} componentProps={props} />;
const TermsAndConditionsWebApi = props => <Async load={import('components/base/TermsAndConditionsWebApi')} componentProps={props} />;
const WorkforceWrapper = props => <Async load={import('components/workforce/WorkforceWrapper')} componentProps={props} />;
const People = props => <Async load={import('components/people/People')} componentProps={props} />;
const CurrentProfile = props => <Async load={import('components/profile/CurrentProfile')} componentProps={props} />;
const ProfileWrapper = props => <Async load={import('components/profile/ProfileWrapper')} componentProps={props} />;
const Teams = props => <Async load={import('components/teams/Teams')} componentProps={props} />;
const SingleTeam = props => <Async load={import('components/teams/SingleTeam')} componentProps={props} />;
const MultipleTeams = props => <Async load={import('components/teams/MultipleTeams')} componentProps={props} />;
const MyTeams = props => <Async load={import('components/teams/MyTeams')} componentProps={props} />;
const MyMemberTeams = props => <Async load={import('components/teams/MyMemberTeams')} componentProps={props} />;
const MyReportingTeams = props => <Async load={import('components/teams/MyReportingTeams')} componentProps={props} />;
const Everyone = props => <Async load={import('components/teams/Everyone')} componentProps={props} />;
const Unassigned = props => <Async load={import('components/teams/Unassigned')} componentProps={props} />;
const NewStarters = props => <Async load={import('components/teams/NewStarters')} componentProps={props} />;
const SingleNewStarterLanding = props => <Async load={import('components/teams/SingleNewStarterLanding')} componentProps={props} />;
const SingleNewStarter = props => <Async load={import('components/teams/SingleNewStarter')} componentProps={props} />;
const Approvals = props => <Async load={import('components/approvals/Approvals')} componentProps={props} />;
const RolesWrapper = props => <Async load={import('components/roles/RolesWrapper')} componentProps={props} />;
const Roles = props => <Async load={import('components/roles/Roles')} componentProps={props} />;
const SingleRole = props => <Async load={import('components/roles/SingleRole')} componentProps={props} />;
const WebApiConfigurations = props => <Async load={import('components/configurations/WebApiConfigurations')} componentProps={props} />;
const Workflows = props => <Async load={import('components/workflows/Workflows')} componentProps={props} />;
const SingleWorkflow = props => <Async load={import('components/workflows/SingleWorkflow')} componentProps={props} />;
const LeaveSettings = props => <Async load={import('components/leave/LeaveSettings')} componentProps={props} />;
const Delegated = props => <Async load={import('components/roles/Delegated')} componentProps={props} />;
const Wrapper = props => <Async load={import('components/base/Wrapper')} componentProps={props} />;
const NotFound = props => <Async load={import('components/not-found/NotFound')} componentProps={props} />;
const SummaryContentAdmin = props => <Async load={import('components/teams/newStarterScreens/SummaryContentAdmin')} componentProps={props} />;
const ConfirmationContent = props => <Async load={import('components/teams/newStarterScreens/ConfirmationContent')} componentProps={props} />;
const Finalise = props => <Async load={import('components/teams/newStarterScreens/Finalise')} componentProps={props} />;
const WelcomeInterceptor = props => <Async load={import('components/activation/WelcomeInterceptor')} componentProps={props} />;
const Welcome = props => <Async load={import('components/activation/Welcome')} componentProps={props} />;
const SignIn = props => <Async load={import('components/activation/SignIn')} componentProps={props} />;
const SignInError = props => <Async load={import('components/activation/SignInError')} componentProps={props} />;
const WhatsNew = props => <Async load={import('components/whats-new/WhatsNew')} componentProps={props} />;
const MfaSettings = props => <Async load={import('components/mfa/MfaSettings')} componentProps={props} />;


export default (
    <Route                          path="/"                               component={TngApp}>
        <IndexRoute                                                         component={Home} />
        <Route                      path="/search/"                         component={Search} />
        <Route                      path="/styles/"                         component={Styles} />
        <Route                      path="/signout/"                        component={SignOut} />
        <Route                      path="/termsandconditions/"             component={TermsAndConditions} />
        <Route                      path="/termsandconditionswebapi/"       component={TermsAndConditionsWebApi} />
        <Route                      path="/members/"                        component={WorkforceWrapper}>
            <IndexRoute                                                     component={People} />
            <Route                  path="/profile/:type/"                  component={CurrentProfile} />
            <Route                  path=":id/:type/"                       component={ProfileWrapper} />
            <Route                  path="/teams/">
                <IndexRoute                                                 component={Teams} />
                <Route              path="team/:id/"                        component={SingleTeam} />
                <Route              path="multipleteams/"                   component={MultipleTeams} />
                <Route              path="my-teams/">
                    <IndexRoute                                             component={MyTeams} />
                    <Route          path="member/"                          component={MyMemberTeams} />
                    <Route          path="reporting/"                       component={MyReportingTeams} />
                </Route>
                <Route              path="everyone/"                        component={Everyone} />
                <Route              path="unassigned/"                      component={Unassigned} />
                <Route              path="new-starters/">
                    <IndexRoute                                             component={NewStarters} />
                    <Route          path=":id/summary-admin/"               component={SummaryContentAdmin} />
                    <Route          path="confirmation/"                    component={ConfirmationContent} />
                    <Route          path=":id/landing/"                     component={SingleNewStarterLanding} />
                    <Route          path="landing/"                         component={SingleNewStarterLanding} />
                    <Route          path=":id/finalise/"                    component={Finalise} />
                    <Route          path=":type/"                           component={SingleNewStarter} />
                    <Route          path=":id/:type/"                       component={SingleNewStarter} />
                </Route>
            </Route>
        </Route>
        <Route                      path="/approvals/"                      component={Approvals} />
        <Route                      path="/settings/">
            <Route                  path="roles/"                           component={RolesWrapper}>
                <IndexRoute                                                 component={Roles} />
                <Route              path=":id/"                             component={SingleRole} />
            </Route>
            <Route                  path="/configurations/"                 component={WebApiConfigurations} />
            <Route                  path="workflows/">
                <IndexRoute                                                 component={Workflows} />
                <Route              path=":id/"                             component={SingleWorkflow} />
            </Route>
            <Route                  path="leave/"                           component={LeaveSettings} />
            <Route                  path="redirectedapprovers/"             component={Delegated} />
            <Route                  path="whats-new/"                       component={WhatsNew} />
            <Route                  path="mfa/"                             component={MfaSettings} />
        </Route>
        <Route                      path="/welcome/:id/"                    components={{main: WelcomeInterceptor}} />
        <Route                      path="/resetpassword/:id/"              components={{main: Welcome}} />
        <Route                      path="/signin/"                         components={{main: SignIn}} />
        <Route                      path="/signinerror/"                    components={{main: SignInError}} />
        <Route                      path=":param"                           component={Wrapper} />
        <Route                      path="/404/"                            component={NotFound} />
        <Route                      path="*"                                component={NotFound} />
    </Route>
);
