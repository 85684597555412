'use strict';

import * as authContext from 'services/authContext';
import constants from 'constants/appConstants';

var TIMEOUT = constants.request.TIMEOUT_PERIOD;
var promise = window.Promise || require('promise');
var request = require('superagent-promise')(require('superagent'), promise);

import moment from 'moment';
import storageUtils from 'services/storageUtils';

function getToken() {

    var retToken = authContext.getToken();
    return retToken;
}

function formatParams( params ) {
    return "?" + Object
        .keys(params)
        .map(function (key) {
            return key + "=" + params[key];
        })
        .join("&");
}

function setDateHeader() {
    return moment().format(constants.dates.formats.SERVER_POST_FORMAT);
}

function setCustomHeaders(r) {
    if (r instanceof XMLHttpRequest) {
        r.setRequestHeader('Authorization', 'Bearer ' + getToken());

        if (storageUtils.getUserIdPFromStorage() && storageUtils.getUserIdPFromStorage() === constants.ENUMS.IDENTITY_PROVIDER.ACCESS) {
            var tId = storageUtils.getValueFromStorage('X-tid');
            var uId = storageUtils.getValueFromStorage('X-uid');
            if (tId && uId) {
                r.setRequestHeader('X-tid', tId);
                r.setRequestHeader('X-uid', uId);
            }
        }
    }
    else {
        r.set('Authorization', 'Bearer ' + getToken());

        if (storageUtils.getUserIdPFromStorage() && storageUtils.getUserIdPFromStorage() === constants.ENUMS.IDENTITY_PROVIDER.ACCESS) {
            var tId = storageUtils.getValueFromStorage('X-tid');
            var uId = storageUtils.getValueFromStorage('X-uid');
            if (tId && uId) {
                r.set('X-tid', tId);
                r.set('X-uid', uId);
            }
        }
    }
}

var DataContext = {
    get(url, queryParams, anonymous, acceptType, custom_timeout) {
        const requestTimeout = custom_timeout !== undefined ? custom_timeout : TIMEOUT;
        if (queryParams === undefined) {
            queryParams = {};
        }
        var r = request
            .get(url)
            .query(queryParams)
            .timeout(requestTimeout)
            .set('Accept', acceptType ? acceptType : 'application/json');
       
        if (!anonymous) {          
            setCustomHeaders(r);
            r.set('X-Date', setDateHeader());
        }
        return r;
    },
    getArrayBuffer(url, queryParams, acceptType) {
        return new promise(function (resolve, reject) {
            
            var xhr = new XMLHttpRequest();
            url = url + formatParams(queryParams);
            xhr.open("GET", url);
            xhr.setRequestHeader("Accept", acceptType);
            setCustomHeaders(xhr);
            xhr.setRequestHeader("X-Date", setDateHeader());
            xhr.responseType = "arraybuffer";
            xhr.onload = resolve;
            xhr.onerror = reject;
            xhr.send();

        });

    },
    put(url, data, anonymous) {
        var r = request
            .put(url)
            .timeout(TIMEOUT)
            .set('Accept', 'application/json')
            .set('X-Date', setDateHeader());

        if (!anonymous) {
            setCustomHeaders(r);
        }
        return r.send(data);
    },
    patch(url, data, anonymous) {
        var r = request
            .patch(url)
            .timeout(TIMEOUT)
            .set('Accept', 'application/json')
            .set('X-Date', setDateHeader());

        if (!anonymous) {
            setCustomHeaders(r);
        }
        return r.send(data);
    },
    del(url, id, anonymous) {
        var r = request
            .del(url)
            .timeout(TIMEOUT)
            .set('Accept', 'application/json')
            .set('X-Date', setDateHeader());

        if (!anonymous) {
            setCustomHeaders(r);
        }
        return r;
    },
    post(url, data, anonymous, custom_timeout, acceptType, responseType, dataType) {
        const requestTimeout = custom_timeout !== undefined ? custom_timeout : TIMEOUT;
        var r = request
            .post(url)
            .timeout(requestTimeout)
            .set('Accept', acceptType ? acceptType : 'application/json')
            .set('X-Date', setDateHeader());

        if (dataType) {
            r.set('dataType', dataType);
        }

        if (responseType) {
            r.set('responseType', responseType);
        }

        if (!anonymous) {
            setCustomHeaders(r);
        }
        return r.send(data);
    },
};

export default DataContext;