'use strict';

import React from 'react';
import { IndexLink, Link } from 'react-router';
import Formsy from 'formsy-react';

import { SubMenu, Item } from 'components/base/SubMenu';
import CD from 'components/utils/ConditionalDisplay';
import TextInput from 'components/base/form-components/TextInput';

import Avatar from 'components/base/Avatar';
import personUtils from 'services/people/personUtils';
import utils from 'services/utils';
import constants from 'constants/appConstants';

import classNames from 'classnames';

import 'styles/Nav.scss';
import 'styles/Header.scss';

import mainESSLogoImageURL from '../../images/logo-ess-nav-003.png';


class Nav extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            settingsListVisible: false,
        };

        this.handleSettingsDropDown = this.handleSettingsDropDown.bind(this);
        this.handleSearchKeyDown = this.handleSearchKeyDown.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
        this.hide = this.hide.bind(this);
    }

    componentDidMount() {
        if (this.props.navSlider) {
            const navSlider = this.props.navSlider;
            navSlider.navdrawerContainer = document.querySelector('.navdrawer-container');

            navSlider.navdrawerContainer.addEventListener('click', function (event) {
                if (!(event.target.classList.contains('no-click') || event.target.parentNode.classList.contains('no-click'))) {
                    navSlider.closeMenu();
                }
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hide);
    }

    handleSettingsDropDown(event) {
        utils.preventDefault(event);

        this.setState({
            settingsListVisible: true,
        });
        document.addEventListener("click", this.hide);
    }

    handleSearchButton() {
        this.searchString();
    }

    handleSearchKeyDown(event) {
        if (event.keyCode === 13) {
            this.searchString();
        }
    }

    searchString = () => {
        this.props.navSlider.closeMenu();
        var string = this.refs.searchField.getValue();
        if (string.trim()) {
            this.props.router.push({
                pathname: '/search/',
                search: "?s={0}".format(this.refs.searchField.getValue()),
                state: {},
            }); 
        }
    };

    hide() {
        this.setState({
            settingsListVisible: false,
        });
        document.removeEventListener("click", this.hide);
    }

    render() {

        var navItems;
        var loginOptions;
        var name;
        var avatar;
        var logo;
        var search;
        let logoImage;
        let primaryContainer;
        let secondaryContainer;
        let anonymous = true;
        const isNewStarter = this.props.userInfo.isNewStarter;

        if (!this.props.nude) {

            if (this.props.userInfo.isLoggedIn) {
                anonymous = false;

                const companyLogo = this.props.companyLogo.logo;
                if (this.props.userInfo.person) {
                    if (!this.props.companyLogo.loading) {
                        const companyName = this.props.userInfo.person.CompanyName;
                        if (companyLogo) {
                            var imgString = "data:image/png;base64," + companyLogo;
                            logoImage = <img width="" src={imgString} alt="Company logo" />;
                        } else {
                            logoImage = <span className="company-name">{companyName}</span>;
                        }
                    }
                }

                if (isNewStarter === undefined || !isNewStarter) {
                    //logged in user which is not a New Starter

                    if (this.props.userInfo.person) {
                        name = personUtils.getFullName(this.props.userInfo.person);
                        avatar = <Avatar profile={this.props.userInfo.person} userInfo={this.props.userInfo} />;
                    } else if (this.props.userInfo.user.profile && this.props.userInfo.user.profile.name) {
                        name = this.props.userInfo.user.profile.name;
                        avatar = <Avatar firstNameFallback={name} />;
                    } else {
                        name = 'Profile';
                    }

                    navItems = this.props.permissions && !this.props.permissions.isError ? (
                        <ul id="left-nav">
                            <CD test={this.props.permissions.isVisible('DashboardDisplay')}>
                                <li className="top-level dashboard"><IndexLink to="/" activeClassName="active">Dashboard</IndexLink></li>
                            </CD>
                            <CD test={this.props.permissions.isVisible('Teams')}>
                                <li className="top-level teams"><Link to={'/teams/'} activeClassName="active" ref="nav-people">Teams</Link></li>
                            </CD>
                            <CD test={
                                this.props.userInfo.person && this.props.userInfo.person.ApproverTypes && this.props.userInfo.person.ApproverTypes.length > 0 ? true : false
                            }
                            >
                                <li className="top-level approvals"><Link to="/approvals/" activeClassName="active" ref="nav-approvals">Approvals</Link></li>
                            </CD>
                            <CD test={
                                this.props.permissions.isVisible('Syncing') ||
                                this.props.permissions.isVisible('SettingsRoles') ||
                                this.props.permissions.isVisible('SettingsLeave') ||
                                this.props.permissions.isVisible('SettingsWorkflow') ||
                                this.props.permissions.isVisible('RedirectedApprovers') ||
                                this.props.permissions.isVisible('WhatsNew')
                            }
                            >
                                <li className="top-level settings">
                                    <Link to="/settings/" ref="nav-settings" className="sub-menu-root no-click" activeClassName="active" onClick={this.handleSettingsDropDown}>
                                        Settings
                                        <i className="arrow-down" />
                                    </Link>

                                    <SubMenu visible={this.state.settingsListVisible}>
                                        <CD test={this.props.permissions.isVisible('Syncing')}>
                                            <Item><Link to="/configurations/" activeClassName="active" ref="nav-configurations"><i className="icon-settings" />General</Link></Item>
                                        </CD>
                                        <Item><Link to="/settings/mfa/" activeClassName="active" ref="nav-configurations"><i className="icon-mfa" />MFA Settings</Link></Item>
                                        <CD test={this.props.permissions.isVisible('SettingsRoles')}>
                                            <Item><Link to="/settings/roles/" activeClassName="active" ref="nav-roles"><i className="icon-settings-roles-permissions" />Roles & Permissions</Link></Item>
                                        </CD>
                                        <CD test={this.props.permissions.isVisible('SettingsWorkflow')}>
                                            <Item><Link to="/settings/workflows/" activeClassName="active" ref="nav-workflow"><i className="icon-settings-workflows" />Workflows</Link></Item>
                                        </CD>
                                        <CD test={this.props.permissions.isVisible('RedirectedApprovers')}>
                                            <Item><Link to="/settings/redirectedapprovers/" activeClassName="active" ref="nav-configurations"><i className="icon-settings-redirected-approvers" />Redirected Approvers</Link></Item>
                                        </CD>
                                        <CD test={this.props.permissions.isVisible('SettingsLeave')}>
                                            <Item><Link to="/settings/leave/" activeClassName="active" ref="nav-leave"><i className="icon-settings-leave" />Leave</Link></Item>
                                        </CD>                                        
                                        <CD test={this.props.permissions.isVisible('WhatsNew')}>
                                            <Item><Link to="/settings/whats-new/" activeClassName="active" ref="nav-whats-new"><i className="icon-whats-new" />What's New</Link></Item>
                                        </CD>
                                    </SubMenu>
                                </li>
                            </CD>

                        </ul>
                    ) : (
                        <ul id="left-nav"></ul>
                    );

                    logo = this.props.permissions && this.props.permissions.isVisible('CompanyLogo') ? (
                        <IndexLink to="/" activeClassName="active" className="logo-area company-logo">
                            {logoImage}
                        </IndexLink>
                    ) : null;

                    search = this.props.permissions && this.props.permissions.isVisible('SearchBar') ? (
                        <Formsy onValidSubmit={this.submitValid}>
                            <TextInput
                                name="searchField"
                                ref="searchField"
                                placeholder="Search"
                                value={this.props.location.query.s ? this.props.location.query.s : ""}
                                autocomplete="off"
                                isSearch
                                onKeyDownCallback={this.handleSearchKeyDown}
                                onButtonCallback={this.handleSearchButton}
                                buttonIconName="icon-search"
                                className="no-click"
                                inputClassName="no-click"
                            />
                        </Formsy>
                    ) : null;

                    loginOptions = (
                        <ul id="profile-nav" className="auth no-click">
                            <li id="avatar">
                                {this.props.userInfo.person ? (
                                    <Link
                                        to={"/members/{0}/{1}/".format(this.props.userInfo.person.Id, utils.slugify(constants.menus.profile.PERSONAL_DETAILS))}
                                    >
                                        {avatar}
                                    </Link>
                                ) : (
                                    <Link
                                        to=""
                                    >
                                        <Avatar firstNameFallback="-" lastNameFallback="-" />
                                    </Link>
                                )}
                            </li>
                            {this.props.permissions && this.props.permissions.isVisible('LoginLogoutButtons') ? (
                                <li id="logout-link" className="top-level">
                                    <Link
                                        to={"/signout/"}
                                        className="app-logout"
                                    >
                                        <i className="icon-signout" title="Sign out" /><span>Sign out</span>
                                    </Link>
                                </li>
                            ) : null}
                        </ul>
                    );

                    const secondaryContClasses = classNames(
                        "container", {
                            search: this.props.permissions && this.props.permissions.isVisible('SearchBar'),
                        }
                    );
                    secondaryContainer = (
                        <div id="secondary-nav" className={secondaryContClasses}>
                            <div className="header">
                                {this.props.userInfo.person ? (
                                    <Link
                                        to={"/members/{0}/{1}/".format(this.props.userInfo.person.Id, utils.slugify(constants.menus.profile.PERSONAL_DETAILS))}
                                    >
                                        {avatar}<span className="label">{name}</span>
                                    </Link>
                                ) : null}
                            </div>
                            {logo}
                            {navItems}
                            <div id="right-nav">
                                {search}
                                {loginOptions}
                                <div className="ess-logo">
                                    <img width="" src={mainESSLogoImageURL} alt="ESS" />
                                </div>
                            </div>
                        </div>
                    );
    
                } else if (isNewStarter !== undefined && isNewStarter) {
                    //New Starters

                    logo = (
                        <div activeClassName="active" className="logo-area company-logo">
                            {logoImage}
                        </div>
                    );
                }

                const loginClasses = classNames(
                    "auth", 
                    "no-click", {
                        buttons: isNewStarter || this.props.permissions && this.props.permissions.isVisible('LoginLogoutButtons'),
                    }
                );
                primaryContainer = (
                    <div id="primary-nav" className="container">                        
                        <div id="evo-container"></div>
                        {logo}
                        <div id="right-nav">
                            <ul id="profile-nav" className={loginClasses}>
                                <li className="top-level">
                                    <div className="logo-area">
                                        <img width="" src={mainESSLogoImageURL} alt="ESS" />
                                    </div>
                                </li>
                                {isNewStarter || (this.props.permissions && this.props.permissions.isVisible('LoginLogoutButtons')) ? (
                                    <li className="top-level logout-link">
                                        <Link
                                            to={"/signout/"}
                                            className="app-logout"
                                        >
                                            <i className="icon-signout" title="Sign out" /><span>Sign out</span>
                                        </Link>
                                    </li>
                                ) : null}
                            </ul>
                        </div>
                    </div>
                );

            } else {
                primaryContainer = (
                    <div id="primary-nav" className="container">
                        <div activeClassName="active" className="logo-area">
                            <img width="" src={mainESSLogoImageURL} alt="ESS" />
                        </div>
                        <div id="right-nav">
                            <ul id="profile-nav" className="no-auth">
                                <li>
                                    <button className="button " onClick={this.props.handleLogin}>Sign in</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                );
            }
        }

        const classes = classNames(
            "navdrawer-container",
            "promote-layer",
            "no-click", {
                'full': this.props.isFull && (isNewStarter || (this.props.permissions && this.props.permissions.isVisible('TopHeader'))),
                'open': this.props.navSlider.isOpen(),
                'anon': anonymous,
                'new-starter': isNewStarter,
            }
        );

        return (
            <nav className={classes}>
                <div id="primary-nav-container">
                    {primaryContainer}
                </div>
                {secondaryContainer}
            </nav>
        );
    }
}

export default Nav; 