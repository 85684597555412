'use strict';

import manager from 'services/apiManager';

import constants from 'constants/appConstants';
import moment from 'moment';

const Api = {
    getAllRedirectedApprovers(now) {
        var url = `${constants.api_urls.DELEGATED_APPROVER}/NotExpired/`;
        var key = constants.actions.GET_ALL_REDIRECTED_APPROVERS;
        return manager.getRequest(key, url, {browserTime: now});
    },

    getRoles() {
        var url = constants.api_urls.ROLE + '/GetAll';
        var key = constants.actions.LOAD_PAGED_ROLES;
        return manager.getRequest(key, url);
    },
    getSingleRole(id) {
        var url = constants.api_urls.ROLE + '(' + id + ')';
        var key = constants.actions.GET_SINGLE_ROLE;
        return manager.getRequest(key, url, {});
    },
    editRole(role) {
        var url = constants.api_urls.ROLE + '/' + role.Id;
        var key = constants.actions.UPDATE_ROLE;
        var params = role;
        return manager.putRequest(key, url, params);
    },
    removeRole(id) {
        var url = constants.api_urls.ROLE + '(' + id + ')';
        var key = constants.actions.REMOVE_ROLE;
        return manager.delRequest(key, url, id);
    },
    addRole(role) {
        var url = constants.api_urls.ROLE;
        var key = constants.actions.ADD_ROLE;
        var params = role;
        return manager.postRequest(key, url, params);
    },
    validateGuid(id) {
        var url = constants.api_urls.ACTIVATION + '/' + id;
        var key = constants.actions.VALIDATE_GUID;
        return manager.getRequest(key, url, {}, true);
    },
    activateUser(user) {
        var url = constants.api_urls.ACTIVATION;
        var key = constants.actions.ACTIVATE_USER;
        return manager.postRequest(key, url, user, true, 30000);
    },
    activateAllUsers() {
        var url = constants.api_urls.MASS_ACTIVATION_ALL;
        var key = constants.actions.ACTIVATE_ALL_USERS;
        return manager.postRequest(key, url, null, false, 120000);
    },
    activateUsers(ids) {
        var url = constants.api_urls.MASS_ACTIVATION;
        var key = constants.actions.ACTIVATE_USERS;
        return manager.postRequest(key, url, ids, false, 120000);
    },
    sendPasscode(id) {
        var url = "{0}/ActivationCode/{1}".format(constants.api_urls.ACTIVATION, id);
        var key = constants.actions.SEND_PASSCODE_GUID;

        return manager.postRequest(key, url, {}, true);
    },
    addAdminRoleWithDuplicatePermissions(role, originalRoleId) {
        var url = "{0}/DuplicateRole/{1}".format(constants.api_urls.ROLE, originalRoleId);
        var key = constants.actions.ADD_ADMIN_ROLE_WITH_DUPLICATE_PERMISSIONS;
        var params = role;
        return manager.postRequest(key, url, params);
    },

    ACTIVATIONS: {
        getActivationToken(guid) {
            const url = constants.api_urls.ACTIVATION + '/getActivationToken/' + guid;
            const params = {
                essId: guid,
            };
            const key = constants.actions.ACTIVATIONS.GET_ACTIVATION_TOKEN;
            return manager.getRequest(key, url, params, true);
        },    
    },

    APPROVALS: {
        getApprovals(workflowType = null) {
            var url = "{0}?WorkflowType={1}&IsAdminApprovalOnly=false&browserTime={2}".format(constants.api_urls.APPROVALS, workflowType, moment().format(constants.dates.formats.SERVER_POST_FORMAT));
            var key = constants.actions.APPROVALS.GET_APPROVALS;
            return manager.getRequest(key, url);
        },
        getOtherApprovals(workflowType = null) {
            var url = "{0}?WorkflowType={1}&IsAdminApprovalOnly=true&browserTime={2}".format(constants.api_urls.APPROVALS, workflowType, moment().format(constants.dates.formats.SERVER_POST_FORMAT));
            var key = constants.actions.APPROVALS.GET_OTHER_APPROVALS;
            return manager.getRequest(key, url);
        },

        approve(approval) {
            var url = "{0}/{1}".format(constants.api_urls.APPROVALS, "Approve");
            var key = constants.actions.APPROVALS.APPROVE;
            var params = {
                ApprovalItem : approval,
                ApprovalAction : "Approve",
            };
            return manager.postRequest(key, url, params);
        },
        decline(approval) {
            var url = "{0}/{1}".format(constants.api_urls.APPROVALS, "Decline");
            var key = constants.actions.APPROVALS.DECLINE;
            var params = {
                ApprovalItem : approval,
                ApprovalAction : "Decline",
            };
            return manager.postRequest(key, url, params);
        },
        getApprovalCount(workflowType) {
            var url = "{0}?$filter=WorkflowType eq '{1}'&$count=true&$top=0".format(constants.api_urls.APPROVALS, workflowType);
            var key = constants.actions.APPROVALS.GET_APPROVAL_COUNT;
            var params = {
                WorkflowType : workflowType,
            };
            return manager.getRequest(key, url, params);
        },
    },

    AUTH: {
        getUserIdp(username) {
            var url = "{0}/GetIdentityProvider/{1}".format(constants.api_urls.ACTIVATION, username);
            const key = constants.actions.AUTH.GET_USER_IDP;
            return manager.getRequest(key, url);
        },
    },

    CONFIGURATION_OPTIONS: {
        getOptions(apiKey) {
            var url = "{0}/GetConfigurationOptions".format(constants.api_urls.WEBAPICONFIGURATION);
            var key = constants.actions.CONFIGURATION_OPTIONS.GET_CONFIG_OPTIONS;
            const params = apiKey ? {apiKey} : {};
            return manager.getCachedRequest(apiKey, key, url, params);
        },
    },

    LEAVE: {
        getLeaveTypes() {
            var url = constants.api_urls.LEAVE_TYPE;
            var key = constants.actions.LEAVE.GET_LEAVE_TYPES;
            return manager.getRequest(key, url);
        },
        updateLeaveType(model) {
            var url = "{0}/{1}".format(constants.api_urls.LEAVE_TYPE, model.Id);
            var key = constants.actions.LEAVE.UPDATE_LEAVE_TYPE;
            return manager.postRequest(key, url, model);
        },
        downloadLeaveAttachment(leaveApplicationId, model) {
            var url = "{0}/{1}/Attachment".format(constants.api_urls.LEAVE_APPLICATIONS, leaveApplicationId);
            var key = constants.actions.LEAVE.DOWNLOAD_LEAVE_ATTACHMENT;
            return manager.getArrayBuffer(key, url, model, 'application/octet-stream');
        },
        getLeaveReasonsForActiveWebApi() {
            var url = constants.api_urls.LEAVE_REASON;
            var key = constants.actions.LEAVE.GET_LEAVE_REASONS;
            return manager.getRequest(key, url);
        },
    },

    LOOKUPS: {
        getSuburbs(lookupString) {
            var url = constants.api_urls.SUBURB_LOOKUP + "?$filter=startswith(SuburbName,'" + lookupString + "')&$orderby=SuburbName&$top=5";
            var key = constants.actions.LOOKUPS.GET_SUBURBS;
            const params = {searchTerm: lookupString};
            return manager.getRequest(key, url, params);
        },
        getStates() {
            var url = constants.api_urls.STATES_LOOKUP;
            var key = constants.actions.LOOKUPS.GET_STATES;
            return manager.getRequest(key, url);
        },
        getCountries(lookupString) {
            var url = constants.api_urls.COUNTRIES_LOOKUP + "?$filter=startswith(CountryName,'" + lookupString + "')&$orderby=CountryName&$top=5";
            var key = constants.actions.LOOKUPS.GET_COUNTRIES;
            const params = {searchTerm: lookupString};
            return manager.getRequest(key, url, params);
        },
        getJobClassifications(id) {
            const url = "{0}/{1}/jobClassification".format(constants.api_urls.LOOKUP, id);
            const key = constants.actions.LOOKUPS.GET_JOB_CLASSIFICATIONS;
            return manager.getRequest(key, url);
        },
        getByType(lookupType) {
            var url = "{0}".format(constants.api_urls.LOOKUP);
            var key = constants.actions.LOOKUPS.LOAD_LOOKUP;
            return manager.getRequest(key, url, {lookupType});
        },
        getCostAccounts(lookupString) {
            var url = constants.api_urls.COSTACCOUNTS_LOOKUP + "?$filter=startswith(CostAccountName,'" + lookupString + "')&$orderby=CostAccountName&$top=5";
            var key = constants.actions.LOOKUPS.GET_COSTACCOUNTS;
            const params = {searchTerm: lookupString};
            return manager.getRequest(key, url, params);
        },
    },

    MEMBERSHIPS: {
        checkMembership(personId, teamId) {
            var url = constants.api_urls.TEAM_MANAGEMENT;
            var key = constants.actions.MEMBERSHIPS.CHECK_MEMBERSHIP;
            var params = {
                personId,
                targetTeamId: teamId,
            };
            return manager.getRequest(key, url, params);
        },
        addMembership(membershipDetails) {
            var url = constants.api_urls.TEAM_MANAGEMENT;
            var key = constants.actions.MEMBERSHIPS.ADD_MEMBERSHIP;
            var params = membershipDetails;
            return manager.postRequest(key, url, params);
        },
        deleteMembership(id) {
            var url = constants.api_urls.TEAM_MANAGEMENT + '/' + id;
            var key = constants.actions.MEMBERSHIPS.DELETE_MEMBERSHIP;
            return manager.delRequest(key, url, id);
        },
        addAdminRole(personId, roleId) {
            var url = "{0}/{1}/SetAdminRole/{2}".format(constants.api_urls.PERSON, personId, roleId);
            var key = constants.actions.MEMBERSHIPS.ADD_ADMIN;
            return manager.postRequest(key, url, {});
        },
        deleteAdminRole(personId) {
            var url = "{0}/{1}/RemoveAdminRole".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.MEMBERSHIPS.DELETE_ADMIN;
            return manager.postRequest(key, url, {});
        },
        setMemberRole(personId, roleId) {
            var url = "{0}/{1}/SetMemberRole/{2}".format(constants.api_urls.PERSON, personId, roleId);
            var key = constants.actions.MEMBERSHIPS.SET_MEMBER_ROLE;
            return manager.postRequest(key, url, {});
        },
        setManagerRole(personId, roleId, teamId) {
            var url = "{0}/{1}/SetManagerRole/{2}/{3}".format(constants.api_urls.PERSON, personId, roleId, teamId);
            var key = constants.actions.MEMBERSHIPS.SET_MANAGER_ROLE;
            return manager.postRequest(key, url, {});
        },
        getRolesandTeamId(personId) {
            var url = "{0}/Memberships/GetRolesandTeamId/{1}".format(constants.api_urls.CURRENT_USER, personId);
            var key = constants.actions.MEMBERSHIPS.GET_ROLES_AND_TEAMID;
            return manager.getRequest(key, url, {});
        },
    },

    NEW_STARTERS: {
        getNewStarters(nextPageLink) {
            let url;
            let key;
            if (nextPageLink === undefined) {
                url = "{0}/Get?$orderby=Id&$count=true".format(constants.api_urls.NEW_STARTERS);
                key = constants.actions.NEW_STARTERS.GET_NEW_STARTERS;
            } else {
                url = nextPageLink;
                key = constants.actions.NEW_STARTERS.GET_MORE_NEW_STARTERS;
            }
            return manager.getRequest(key, url);
        },
        getNewStartersCount() {
            const url = "{0}/Get?$count=true&$top=0".format(constants.api_urls.NEW_STARTERS);
            const key = constants.actions.NEW_STARTERS.GET_NEW_STARTERS_COUNT;
            return manager.getRequest(key, url);
        },
        addNewStarter(data) {
            const url = "{0}/Add".format(constants.api_urls.NEW_STARTERS);
            var key = constants.actions.NEW_STARTERS.ADD_NEW_STARTER;
            return manager.postRequest(key, url, data);
        },
        addNonPayroll(data) {
            const url = "{0}/AddNonPayroll".format(constants.api_urls.NEW_STARTERS);
            var key = constants.actions.NEW_STARTERS.ADD_NEW_STARTER;
            return manager.postRequest(key, url, data);
        },
        resendForm(id, emailAddress) {
            const url = "{0}/ResendForm".format(constants.api_urls.NEW_STARTERS);
            var key = constants.actions.NEW_STARTERS.RESEND_FORMS_NEW_STARTER;
            var params = {
                WorkflowItemId : id,
                EmailAddress : emailAddress,
            };
            return manager.postRequest(key, url, params);
        },
        deleteNewStarter(id) {
            const url = "{0}/{1}/Delete".format(constants.api_urls.NEW_STARTERS, id);
            var key = constants.actions.NEW_STARTERS.DELETE_NEW_STARTER;
            return manager.delRequest(key, url, id);
        },
        getNewStarter(newStarterWorkflowId, screenType = "welcome", requestType = "") {
            const workflowItemExt = newStarterWorkflowId ? "\\" + newStarterWorkflowId : "";
            const requestTypeExt = requestType ? "\\" + requestType : requestType;
            const url = "{0}{1}{2}".format(constants.api_urls.NEW_STARTERS, workflowItemExt, requestTypeExt);
            var key = constants.actions.NEW_STARTERS.GET_NEW_STARTER;
            const data = {screenType};
            return manager.getRequest(key, url, data);
        },
        updateNewStarter(workflowItemId, newStarter, screenType) {
            const url = "{0}/{1}/Update/{2}".format(constants.api_urls.NEW_STARTERS, workflowItemId, screenType);
            var key = constants.actions.NEW_STARTERS.UPDATE_NEW_STARTER;
            return manager.postRequest(key, url, newStarter);
        },
        updateNewStarterStatus(workflowItemId, action) {
            let url;
            if (workflowItemId) {
                url = "{0}/{1}/ChangeStatus/{2}".format(constants.api_urls.NEW_STARTERS, workflowItemId, action);
            } else {
                url = "{0}/ChangeStatus/{1}".format(constants.api_urls.NEW_STARTERS, action);
            }

            var key = constants.actions.NEW_STARTERS.UPDATE_NEW_STARTER_STATUS;
            return manager.postRequest(key, url, workflowItemId);
        },
        getNewStarterStatus(workflowItemId) {
            const url = "{0}/{1}/Status".format(constants.api_urls.NEW_STARTERS, workflowItemId);
            const key = constants.actions.NEW_STARTERS.GET_NEW_STARTER_STATUS;
            return manager.getRequest(key, url, workflowItemId);
        },
        getNewStarterCompletedPages: (workflowItemId) => {
            const url = "{0}/{1}/CompletedPages".format(constants.api_urls.NEW_STARTERS, workflowItemId);
            const key = constants.actions.NEW_STARTERS.GET_NEW_STARTER_COMPLETED_PAGES;
            return manager.getRequest(key, url, workflowItemId);
        },
        finaliseNewStarter(workflowItemId, data) {
            const url = "{0}/{1}/Finalise".format(constants.api_urls.NEW_STARTERS, workflowItemId);
            var key = constants.actions.NEW_STARTERS.FINALISE_NEW_STARTER;
            return manager.postRequest(key, url, data);
        },
        getActionsCount: () => {
            const url = "{0}/ActionRequiredCount".format(constants.api_urls.NEW_STARTERS);
            const key = constants.actions.NEW_STARTERS.GET_ACTION_REQUIRED_COUNT;                
            return manager.getRequest(key, url);
        },
    },

    PEOPLE: {
        getPeople() {
            var url = "{0}?$orderby=FirstName&$count=true".format(constants.api_urls.PERSON);
            var key = constants.actions.PEOPLE.LOAD_PEOPLE_FROM_SERVER;
            return manager.getRequest(key, url);
        },
        getMoreFilteredPeople(nextPageLink) {
            var url = nextPageLink;
            var key = constants.actions.PEOPLE.LOAD_MORE_FILTERED_PEOPLE_FROM_SERVER;
            return manager.getRequest(key, url);
        },
        getMorePeople(nextPageLink) {
            var url = nextPageLink;
            var key = constants.actions.PEOPLE.LOAD_MORE_PEOPLE_FROM_SERVER;
            return manager.getRequest(key, url);
        },
        getFilteredPeople(lookupString, top = 5, skip = 0, managersOnly = false) {
            var url = "{0}?$filter=(contains(FirstName, '{1}') or contains(LastName, '{1}') or contains(PreferredName, '{1}')){4}&$top={2}&skip={3}&$count=true".format(constants.api_urls.PERSON, lookupString.replace("'", "''"), top, skip, managersOnly ? " and Memberships/any(m: m/Role/RoleType eq 'Manager')" : "");
            var lookupStringArray = lookupString.split(" ");
            var firstName = lookupStringArray[0];
            var lastName = lookupStringArray[1];
            if (lookupStringArray.length > 1) {
                url = "{0}?$filter=((contains(FirstName, '{1}') or contains(PreferredName, '{1}')) and contains(LastName, '{2}')){5}&$top={3}&skip={4}&$count=true".format(constants.api_urls.PERSON, firstName.replace("'", "''"), lastName.replace("'", "''"), top, skip, managersOnly ? " and Memberships/any(m: m/Role/RoleType eq 'Manager')" : "");
            }
            var key = constants.actions.PEOPLE.LOAD_FILTERED_PEOPLE;
            const params = {searchTerm: lookupString};
            return manager.getRequest(key, url, params);
        },
        getSinglePerson(id, reset) {
            if (reset === undefined) {
                reset = false;
            }
            var url = "{0}/{1}/".format(constants.api_urls.PERSON, id);
            var key = constants.actions.PEOPLE.LOAD_SINGLE_PERSON;
            return manager.getRequest(key, url, {id, reset});
        },
        getSinglePersonByWorkflowItemId: (id, reset) => {
            if (reset === undefined) {
                reset = false;
            }
            var url = "{0}/{1}/Finalise".format(constants.api_urls.NEW_STARTERS, id);
            var key = constants.actions.PEOPLE.LOAD_SINGLE_PERSON;
            return manager.getRequest(key, url, {id, reset});
        },
        updatePerson(person) {
            var url = constants.api_urls.PERSON + '(' + person.Id + ')';
            var key = constants.actions.PEOPLE.UPDATE_PERSON;
            var params = person;
            return manager.putRequest(key, url, params);
        },
        removePerson(id) {
            var url = constants.api_urls.PERSON + '(' + id + ')';
            var key = constants.actions.PEOPLE.REMOVE_PERSON;
            return manager.delRequest(key, url, id);
        },
        addPerson(person) {
            var url = constants.api_urls.PERSON;
            var key = constants.actions.PEOPLE.ADD_PERSON;
            var params = person;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonName(personId) {
            var url = "{0}/{1}/PersonalInfo".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_NAME;
            return manager.getRequest(key, url);
        },
        updateName(personId, model) {
            var url = "{0}/{1}/PersonalInfo".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_NAME;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonDemographic(personId) {
            var url = "{0}/{1}/PersonalAdditionalInfo".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_DEMOGRAPHIC;
            return manager.getRequest(key, url);
        },
        updateDemographic(personId, model) {
            var url = "{0}/{1}/PersonalAdditionalInfo".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_DEMOGRAPHIC;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        updatePersonalContact(personId, model) {
            var url = "{0}/{1}/PersonContactDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_PERSON;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonMedicalConditions(personId) {
            var url = "{0}/{1}/PersonMedicalCondition".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_MEDICAL_CONDITIONS;
            return manager.getRequest(key, url);
        },
        updateMedicalConditions(personId, model) {
            var url = "{0}/{1}/PersonMedicalCondition".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_MEDICAL_CONDITIONS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonWorkContacts(personId) {
            var url = "{0}/{1}/PersonWorkContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_WORK_CONTACTS;
            return manager.getRequest(key, url);
        },
        updateSinglePersonWorkContacts(personId, model) {
            var url = "{0}/{1}/PersonWorkContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_WORK_CONTACTS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonPersonalContacts(personId) {
            var url = "{0}/{1}/PersonContactDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_PERSONAL_CONTACTS;
            return manager.getRequest(key, url);
        },
        getSinglePersonAddressContacts(personId) {
            var url = "{0}/{1}/PersonAddressContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_ADDRESS_CONTACTS;
            return manager.getRequest(key, url);
        },
        updateAddressContact(personId, addressDetails) {
            var url = "{0}/{1}/PersonAddressContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_ADDRESS_CONTACTS;
            var params = addressDetails;
            return manager.postRequest(key, url, params);
        },
        updateSinglePersonPersonalContacts(personId, model) {
            var url = "{0}/{1}/PersonContactDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_PERSONAL_CONTACTS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonEmailContacts(personId) {
            var url = "{0}/{1}/PersonEmailContacts".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_EMAIL_CONTACTS;
            return manager.getRequest(key, url);
        },
        updateDefaultEmailContact(personId, contactType) {
            var url = "{0}/{1}/UpdateDefaultEmail/{2}".format(constants.api_urls.PERSON, personId, contactType);
            var key = constants.actions.PEOPLE.UPDATE_PERSON_DEFAULT_EMAIL_CONTACTS;
            return manager.postRequest(key, url);
        },
        getSinglePersonBanks(personId) {
            var url = "{0}/{1}/PersonBankDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_BANKS;
            return manager.getRequest(key, url);
        },
        updateSinglePersonBanks(personId, model) {
            var url = "{0}/{1}/PersonBankDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_BANKS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonEmergencyContacts(personId) {
            var url = "{0}/{1}/PersonEmergencyContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_EMERGENCY_CONTACTS;
            return manager.getRequest(key, url);
        },
        updateSinglePersonEmergencyContacts(personId, model) {
            var url = "{0}/{1}/PersonEmergencyContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_EMERGENCY_CONTACTS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        removePersonEmergencyContacts(personId) {
            var url = "{0}/{1}/PersonEmergencyContact".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.REMOVE_PERSON_EMERGENCY_CONTACTS;
            return manager.delRequest(key, url, personId);
        },
        getSinglePersonLeave(personId, model) {
            var url = "{0}/{1}/GetLeaveApplications".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        addSinglePersonLeave(personId, model) {
            var url = "{0}/{1}/LeaveApplications".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        editSinglePersonLeave(personId, model) {
            var url = "{0}/{1}/LeaveApplications".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE;
            var params = model;
            return manager.putRequest(key, url, params);
        },
        cancelSinglePersonLeave(personId, model) {
            var url = "{0}/{1}/CancelLeave".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonLeaveReasons(personId) {
            var url = "{0}/{1}/LeaveReasons".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_REASONS;
            return manager.getRequest(key, url);
        },
        updateSinglePersonLeaveReasons(personId, model) {
            var url = "{0}/{1}/LeaveReasons".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_LEAVE_REASONS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonLeaveBalances(personId, date, leaveTypeId) {
            let key = constants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_BALANCES;
            const url = "{0}/{1}/LeaveBalances".format(constants.api_urls.PERSON, personId);
            const params = {
                forecastDate: date,
            };
            if (leaveTypeId) {
                key = constants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_ENTITLEMENTS;
                params.LeaveTypeIds = leaveTypeId;
            }
            return manager.getRequest(key, url, params);
        },
        getSinglePersonLeaveBalanceValidation(personId, leaveApp) {
            var url = "{0}/{1}/LeaveValidation".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_LEAVE_BALANCE_VALIDATION;
            var params = leaveApp;
            return manager.postRequest(key, url, params);
        },
        getSinglePersonPayAdvices(personId, model) {
            var url = "{0}/{1}/PayAdviceDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_PAY_ADVICES;
            return manager.getRequest(key, url, model, null, null, 60000);
        },
        getSinglePersonPaySummaries(personId, model) {
            var url = "{0}/{1}/PaymentSummaryData".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_PAY_SUMMARIES;
            return manager.getRequest(key, url, model, null, null, 60000);
        },
        getSinglePersonPayAdvicePdf(personId, model) {
            var url = "{0}/{1}/PayAdvicePdf".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.DOWNLOAD_PERSON_PAY_ADVICE_PDF;
            return manager.getArrayBuffer(key, url, model, 'application/pdf');
        },
        getSinglePersonPaySummaryPdf(personId, model) {
            var url = "{0}/{1}/PaySummaryPdf".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.DOWNLOAD_PERSON_PAY_SUMMARY_PDF;
            return manager.getArrayBuffer(key, url, model, 'application/pdf');
        },
        getSinglePersonLeaveTraces(personId, appId) {
            var url = "{0}/{1}/GetLeaveTraces/{2}".format(constants.api_urls.PERSON, personId, appId);
            var key = constants.actions.PEOPLE.UPDATE_LEAVE_APPS_TRACES;
            return manager.getRequest(key, url);
        },
        getSinglePersonWorkflowScope(personId, workflowType, browserTime) {
            var url = constants.api_urls.WORKFLOW_DETAIL;
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_WORKFLOW_DETAILS;
            var params = {personId, workflowType, browserTime};
            return manager.getRequest(key, url, params);
        },
        getSinglePersonEmployment(personId) {
            var url = "{0}/{1}/Employment".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_EMPLOYMENT;
            return manager.getRequest(key, url);
        },
        getSinglePersonEmployer(personId) {
            var url = "{0}/{1}/Employer".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_EMPLOYER;
            return manager.getRequest(key, url);
        },
        getSinglePersonSalary(personId) {
            var url = "{0}/{1}/Salary".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_SALARY;
            return manager.getRequest(key, url);
        },
        getSinglePersonTax(personId) {
            var url = "{0}/{1}/Tax".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_TAX;
            return manager.getRequest(key, url);
        },
        getSinglePersonTfn(personId) {
            var url = "{0}/{1}/Tfn".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_TFN;
            return manager.getRequest(key, url);
        },
        getSinglePersonRolesTeams(personId) {
            var url = "{0}/{1}/Teams?$orderby=Name&$count=true".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_ROLES_TEAMS;
            return manager.getRequest(key, url);
        },
        getSinglePersonAssociatedTeams(personId) {
            var url = "{0}/{1}/AssociatedTeams".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_ASSOCIATED_TEAMS;
            return manager.getRequest(key, url);
        },
        getSinglePersonSuperannuation(personId) {
            const url = constants.api_urls.PERSON_SUPERANNUATION;
            const params = {
                PersonId: personId,
            };
            const key = constants.actions.PEOPLE.GET_SINGLE_PERSON_SUPERANNUATION;
            return manager.getRequest(key, url, params);
        },
        getAvatar(personId,size) {
            var url = "{0}/{1}/Avatar/{2}".format(constants.api_urls.PERSON, personId, size);
            var key = constants.actions.PEOPLE.GET_AVATAR;
            return manager.getRequest(key, url);
        },
        updateAvatar(personId, model) {
            var url = "{0}/{1}/Avatar".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.UPDATE_AVATAR;
            var params = model;
            return manager.postRequest(key, url, params);
        },
        deleteAvatar(personId) {
            var url = "{0}/{1}/Avatar".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.DELETE_AVATAR;
            return manager.delRequest(key, url);
        },
        getSinglePersonWorkDaysHours(personId, startDate, endDate, startSession, endSession) {
            var url = "{0}?personId={1}&startDate={2}&endDate={3}&startSession={4}&endSession={5}"
                .format(constants.api_urls.GET_PERSON_WORK_HOURS_DAYS, personId, startDate, endDate, startSession, endSession);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_WORK_DAYS_HOURS;
            var params = {searchParams: {
                startDate, endDate, startSession, endSession,
            }};
            return manager.getRequest(key, url, params);
        },
        getDelegateApprover(originalPersonId) {
            var url = constants.api_urls.DELEGATED_APPROVER;
            var key = constants.actions.PEOPLE.GET_DELEGATE_APPROVER;
            return manager.getRequest(key, url, {
                originalPersonId,
            });
        },
        editDelegateApprover(model) {
            var url = constants.api_urls.DELEGATED_APPROVER;
            var key = constants.actions.PEOPLE.EDIT_DELEGATE_APPROVER;
            return manager.postRequest(key, url, model);
        },
        deleteDelegateApprover(originalPersonId) {
            var url = "{0}?Id={1}".format(constants.api_urls.DELEGATED_APPROVER, originalPersonId);
            var key = constants.actions.PEOPLE.DELETE_DELEGATE_APPROVER;
            return manager.delRequest(key, url);
        },
        getMyDelegatedRedirects(id) {
            var url = constants.api_urls.DELEGATED_TO_ME;
            var key = constants.actions.PEOPLE.GET_DELEGATED_TO_ME;
            return manager.getRequest(key, url, {
                redirectedPersonId: id,
            });
        },
        getSinglePersonApproverTypes(id) {
            var url = "{0}/{1}/ApproverType?personId={1}&browserTime={2}".format(constants.api_urls.PERSON, id, "2016-10-05");
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_APPROVER_TYPES;
            return manager.getRequest(key, url);
        },
        // T/S - Temporary to leave it under /Person
        getSinglePersonCurrentPayPeriod(personId) {
            var url = "{0}/{1}/PersonTimesheetDetails".format(constants.api_urls.PERSON, personId);
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_CURRENTPAYPERIOD;
            return manager.getRequest(key, url);
        },
        getSinglePersonTimesheets(personId, date) {
            
            //test REMOVE IT !!!
            var url = "{0}/{1}/{2}".format(constants.api_urls.TIMESHEET, personId, date);
            
            var key = constants.actions.PEOPLE.GET_SINGLE_PERSON_TIMESHEETS;
            const tsData = manager.getRequest(key, url);    
            console.log("t/S", tsData);        
            return tsData;
            
            // return manager.mockSuccessfulRequest(key, null, {
            //   "noSqlId": null,
            //   "personId": 1045,
            //   "workflowItemId" : 8888,
            //   "periodStartDate": "2019-05-20T11:15:00.000Z",
            //   "periodEndDate": "2019-05-26T11:15:00.000Z",
            //   "shifts": []
            // }
            // );
            
            // return manager.mockSuccessfulRequest(key, null, {
            //   "noSqlId": "90c0e923-a8b5-46a7-a867-875eaffca90b",
            //   "personId": 1045,
            //   "workflowItemId" : 8888,
            //   "periodStartDate": "2019-05-20T11:15:00.000Z",
            //   "periodEndDate": "2019-05-26T11:15:00.000Z",
            //   "shifts": [
            //   {
            //     "startDateTime": "2019-05-20T08:30:00.000Z",
            //     "endDateTime": "2019-05-20T11:30:00.000Z",
            //     "breakHours": 0.5,
            //     "totalHours": 2.5,
            //     "comments": "First shift",
            //     "costAccount": "NSW Executive",
            //     "jobCode": "Administrator" 
            //   },               
            //   {
            //     "startDateTime": "2019-05-20T13:30:00.000Z",
            //     "endDateTime": "2019-05-20T17:30:00.000Z",
            //     "breakHours": 0.5,
            //     "totalHours": 3.5,
            //     "comments": "Second shift",
            //     "costAccount": "Chatswood IT",
            //     "jobCode": "Product" 
            //   },               
            //   {
            //     "startDateTime": "2019-05-22T07:45:00.000Z",
            //     "endDateTime": "2019-05-22T17:50:00.000Z",
            //     "breakHours": 0.75,
            //     "totalHours": 8.0,
            //     "comments": "CC",
            //     "costAccount": "Queensland",
            //     "jobCode": "Accountant" 
            //   },
            //   {
            //     "startDateTime": "2019-05-23T08:00:00.000Z",
            //     "endDateTime": "2019-05-23T18:00:00.000Z",
            //     "breakHours": 1.25,
            //     "totalHours": 7.6,
            //     "comments": "BB",
            //     "costAccount": "Client Services",
            //     "jobCode": "Support" 
            //   },
            //   ]
            // }
            // );
        },
        updateSinglePersonTimesheets(personId, workflowId, model) {
            //var url = "{0}/{1}/PersonTimesheetDetails".format(constants.api_urls.PERSON, personId);
            var url = "{0}/{1}/{2}".format(constants.api_urls.TIMESHEET, personId, workflowId);
            var key = constants.actions.PEOPLE.UPDATE_SINGLE_PERSON_TIMESHEETS;
            var params = model;
            return manager.postRequest(key, url, params);
        },
    },

    PERMISSIONS: {
        getPermissions() {
            var url = constants.api_urls.CURRENT_USER + '/Permissions';
            var key = constants.actions.PERMISSIONS.GET_PERMISSIONS;
            return manager.getRequest(key, url);
        },
        getPermissionsByRole(roleId) {
            var url = "{0}/{1}/{2}".format(constants.api_urls.ROLE, roleId, 'Permissions');
            var key = constants.actions.PERMISSIONS.GET_PERMISSIONS_BY_ROLE;
            return manager.getRequest(key, url);
        },
        updateRolePermissions(roleId, model) {
            var url = "{0}/{1}/{2}".format(constants.api_urls.ROLE, roleId, 'Permissions');
            var key = constants.actions.PERMISSIONS.UPDATE_ROLE_PERMISSIONS;
            var params = model;
            return manager.putRequest(key, url, params);
        },
    },

    PERSON: {
        activateIdentity(personId) {
            var url = constants.api_urls.PERSON_IDENTITY + '/' + personId;
            var key = constants.actions.PERSON.ACTIVATE_IDENTITY;
            var params = {
                Id: personId,
            };
            return manager.postRequest(key, url, params, false, 62000);
        },
        getLoginPerson() {
            var url = "{0}/{1}?browserTime={2}".format(constants.api_urls.CURRENT_USER, 'Profile', moment().format(constants.dates.formats.SERVER_POST_FORMAT));
            var key = constants.actions.PERSON.GET_LOGGED_IN_PERSON;

            return manager.getRequest(key, url);
        },
        loginPerson() {
            var url = constants.api_urls.LOGIN_PERSON;
            var key = constants.actions.PERSON.LOGIN_PERSON;

            return manager.putRequest(key, url);
        },
        updateLastLoginDateTime(personId, lastLoginDateTime) {
            var url = "{0}/updateLastLogin/{1}/{2}".format(constants.api_urls.PERSON_IDENTITY, personId, lastLoginDateTime);
            var key = constants.actions.PERSON.UPDATE_LAST_LOGIN;

            return manager.putRequest(key, url);
        },
        getPersonIdentity(personId) {
            var url = "{0}/{1}/".format(constants.api_urls.PERSON_IDENTITY, personId);
            var key = constants.actions.PERSON.GET_PERSON_IDENTITY;

            return manager.getRequest(key, url);
        },
        closePersonIdentity(personId) {
            var url = "{0}/{1}/close".format(constants.api_urls.PERSON_IDENTITY, personId);
            var key = constants.actions.PERSON.CLOSE_PERSON_IDENTITY;

            return manager.putRequest(key, url);
        },
        closeCurrentPersonIdentity() {
            var url = "{0}/close".format(constants.api_urls.PERSON_IDENTITY);
            var key = constants.actions.PERSON.CLOSE_CURRENT_PERSON_IDENTITY;

            return manager.putRequest(key, url);
        },
        reopenPersonIdentity(personId, unlockAccountAction) {
            var url = "{0}/{1}/reopen/{2}".format(constants.api_urls.PERSON_IDENTITY, personId, unlockAccountAction);
            var key = constants.actions.PERSON.REOPEN_PERSON_IDENTITY;

            return manager.putRequest(key, url);
        },
        updateUsername(personId, username) {
            var url = "{0}/{1}/changeUsername/{2}".format(constants.api_urls.PERSON_IDENTITY, personId,username);
            var key = constants.actions.PERSON.UPDATE_USERNAME;
            return manager.postRequest(key, url);
        },
        sendForgotPassword(email) {
            var url = "{0}/{1}/forgot".format(constants.api_urls.PERSON_IDENTITY, email);
            var key = constants.actions.PERSON.SEND_FORGOT_PASSWORD;

            return manager.postRequest(key, url, {}, true);
        },
        sendResetIdentityPassword(personId) {
            var url = "{0}/{1}/reset".format(constants.api_urls.PERSON_IDENTITY, personId);
            var key = constants.actions.PERSON.SEND_RESET_IDENTITY_PASSWORD;

            return manager.postRequest(key, url);
        },
        cancelActivation(personRefId) {
            var url = "{0}/{1}/expire".format(constants.api_urls.ACTIVATION, personRefId);
            var key = constants.actions.PERSON.CANCEL_ACTIVATION;

            return manager.putRequest(key, url);
        },        
        getPersonActivationInfo(personRefId) {
            var url = "{0}/person/{1}".format(constants.api_urls.ACTIVATION, personRefId);
            var key = constants.actions.PERSON.GET_ACTIVATION_INFO;

            return manager.getRequest(key, url);
        },
        resetMFA(personId) {
            var url = "{0}/{1}/resetMFA".format(constants.api_urls.PERSON_IDENTITY, personId);
            var key = constants.actions.PERSON.RESET_MFA;

            return manager.putRequest(key, url);
        },
        getCompanyLogo() {
            const url = constants.api_urls.LOGO;
            const key = constants.actions.PERSON.GET_COMPANY_LOGO;
            return manager.getRequest(key, url);
        },
        uploadCompanyLogo(image) {
            const url = constants.api_urls.LOGO;
            const key = constants.actions.PERSON.UPLOAD_COMPANY_LOGO;
            var params = {
                FileName : "logo.png",
                Image: image,
            };
            return manager.postRequest(key, url, params);
        },
        getNotifications() {
            var url = "{0}/Notifications".format(constants.api_urls.CURRENT_USER);
            var key = constants.actions.PERSON.GET_NOTIFICATIONS;
            const queryParams = constants.notificationOffset ? {offset: constants.notificationOffset} : {};
            return manager.getRequest(key, url, queryParams);
        },
        setLastSeenNotificationDate() {
            var url = "{0}/SetLastSeenNotificationDate".format(constants.api_urls.CURRENT_USER);
            var key = constants.actions.PERSON.SET_NOTIFICATION_DATE;
            return manager.postRequest(key, url);
        },
    },

    REPORTS: {
        getLeaveBalanceReport(model) {
            var url = "{0}/LeaveBalance".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getMedicalAndEmergencyReport(model) {
            var url = "{0}/MedicalAndEmergencyDetails".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getLeaveHistoryReport(model) {
            var url = "{0}/LeaveHistory".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getLeaveHistoryByDateRangeReport(model) {
            var url = "{0}/LeaveHistory".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;            
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getPersonalChangesAuditReport(model) {
            const url = "{0}/AuditLogPersonalChanges".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getLeaveAuditReport(model) {
            const url = "{0}/AuditLogLeave".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getMaintenanceAuditReport(model) {
            const url = "{0}/AuditLogMaintenance".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getActivationReport(model) {
            const url = "{0}/Activation".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getTeamsReport(model) {
            const url = "{0}/Team".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getAllRolesReport(model) {
            const url = "{0}/AllRoles".format(constants.api_urls.REPORTS);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getSingleRoleReport(roleId, model) {
            const url = "{0}/SingleRole/{1}".format(constants.api_urls.REPORTS, roleId);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },
        getRolesByRoleTypeReport(roleTypeId, model) {
            const url = "{0}/RoleType/{1}".format(constants.api_urls.REPORTS, roleTypeId);
            const key = constants.actions.REPORTS.DOWNLOAD_REPORT;
            return manager.getArrayBuffer(key, url, model, 'text/csv');
        },        
    },

    TEAMS: {
        getTeams() {
            var url = constants.api_urls.CURRENT_USER + '/Teams?$orderby=Name&$count=true';
            var key = constants.actions.TEAMS.GET_ALL_TEAMS;
            return manager.getRequest(key, url);
        },
        getTeamsByDisplayType(displayTypeId, paging) {
            var url = '{0}/DisplayType/{1}/Teams?{2}'.format(constants.api_urls.CURRENT_USER, displayTypeId, paging ? "$orderby=Name&$count=true" : "");
            var key = constants.actions.TEAMS.GET_ALL_TEAMS_BY_DISPLAY_TYPE;
            return manager.getRequest(key, url, {displayTypeId});
        },
        getMoreTeams(nextPageLink, displayTypeId) {
            var queryParams = {};
            if (displayTypeId !== undefined) {
                queryParams = {
                    displayTypeId,
                };
            }
            var url = nextPageLink;
            var key = constants.actions.TEAMS.GET_MORE_TEAMS;
            return manager.getRequest(key, url, queryParams);
        },
        getTeam(id) {
            var url = "{0}/{1}".format(constants.api_urls.TEAM, id);
            var key = constants.actions.TEAMS.GET_SINGLE_TEAM;
            return manager.getRequest(key, url);
        },
        getMultipleTeams(data) {
            var url = "{0}/{1}".format(constants.api_urls.TEAMS, data);
            var key = constants.actions.TEAMS.GET_MULTIPLE_TEAMS;            
            var responseData = manager.getRequest(key, url);
            return responseData;
        },
        getMultipleTeamMembers(data) {
            var url = constants.api_urls.GET_PEOPLE_BY_TEAM_IDS;
            var key = constants.actions.TEAMS.GET_MULTIPLE_TEAM_MEMBERS;            
            var responseData = manager.postRequest(key, url, data);
            return responseData;
        },
        getMultipleTeamManagers(data) {
            var url = constants.api_urls.GET_PEOPLE_BY_TEAM_IDS;
            var key = constants.actions.TEAMS.GET_MULTIPLE_TEAM_MANAGERS;            
            var responseData = manager.postRequest(key, url, data);
            return responseData;
        },
        getTeamMembers(data) {
            var url = constants.api_urls.GET_PEOPLE_SUMMARY;
            var key = constants.actions.TEAMS.GET_SINGLE_TEAM_MEMBERS;
            return manager.postRequest(key, url, data);
        },
        getMoreTeamMembers(data) {
            var url = constants.api_urls.GET_PEOPLE_SUMMARY;
            var key = constants.actions.TEAMS.GET_MORE_SINGLE_TEAM_MEMBERS;
            return manager.postRequest(key, url, data);
        },
        getTeamManagers(data) {
            var url = constants.api_urls.GET_PEOPLE_SUMMARY;
            var key = constants.actions.TEAMS.GET_SINGLE_TEAM_MANAGERS;
            return manager.postRequest(key, url, data);
        },
        getMoreTeamManagers(data) {
            var url = constants.api_urls.GET_PEOPLE_SUMMARY;
            var key = constants.actions.TEAMS.GET_MORE_SINGLE_TEAM_MANAGERS;
            return manager.postRequest(key, url, data);
        },
        getActivationStatus(teamId) {
            var url = "{0}/{1}".format(constants.api_urls.GET_ACTIVATION_STATUS, teamId);
            var key = constants.actions.TEAMS.GET_PEOPLE_ACTIVATION_STATUS;

            return manager.postRequest(key, url);
        },
        addTeam(team) {
            var url = constants.api_urls.TEAM;
            var key = constants.actions.TEAMS.ADD_TEAM;
            var params = team;
            return manager.postRequest(key, url, params);
        },
        editTeam(team) {
            var url = constants.api_urls.TEAM;
            var key = constants.actions.TEAMS.EDIT_TEAM;
            var params = team;
            return manager.putRequest(key, url, params);
        },
        deleteTeam(teamId) {
            var url = "{0}?id={1}".format(constants.api_urls.TEAM, teamId);
            var key = constants.actions.TEAMS.DELETE_TEAM;
            return manager.delRequest(key, url);
        },
        getFilteredTeams(lookupString, top, skip) {
            var url = "{0}?$filter=startswith(Name, '{1}')&$top={2}&skip={3}&$orderby=Name&$count=true".format(constants.api_urls.TEAM, lookupString.replace("'", "''"), top, skip);
            var key = constants.actions.TEAMS.LOAD_FILTERED_TEAMS;
            const params = {searchTerm: lookupString};
            return manager.getRequest(key, url, params);
        },
        getTeamMembersCount() {
            var url = constants.api_urls.TEAM_MEMBERS_COUNT;
            var key = constants.actions.TEAMS.GET_TEAM_MEMBERS_COUNT;
            return manager.getRequest(key, url);
        },
        getUsersManagers() {
            var url = constants.api_urls.GET_USERS_MANAGERS;
            var key = constants.actions.TEAMS.GET_USERS_MANAGERS;
            return manager.getRequest(key, url);
        },
        getUsersMembersOnLeave(date) {
            var url = constants.api_urls.GET_PEOPLE;
            var key = constants.actions.TEAMS.GET_USERS_MEMBERS_ON_LEAVE;
            var data = {
                IsSortAscending: true,
                viewType: constants.ENUMS.TEAM_VIEW_OPTIONS.LEAVE_DASHBOARD,
                requestTeamType: constants.ENUMS.TEAM_REQUEST_TYPES.EVERYONE_IN_MY_TEAMS.NAME,
                pageNumber: 1,
                pageSize: 20,
                leaveRequest:{
                    leaveOrderDay: date,
                    startDate: date,
                    endDate: date},
                roleTypes: ["personal","manager"],
                excludeRoleTypes: [],
            };
            return manager.postRequest(key, url, data);
        },
        getUnassignedTeamInfo() {
            var url = constants.api_urls.GET_UNASSIGNED_TEAM_INFO;
            var key = constants.actions.TEAMS.GET_UNASSIGNED_TEAM_INFO;
            var data = {};
            return manager.getRequest(key, url, data);
        },
    },

    WEBAPI: {
        getWebAPILogs(model ) {
            var url = constants.api_urls.WEBAPI_LOG + "?$count=true&$orderby=Id desc";
            var key = constants.actions.WEBAPI.GET_WEB_API_LOGS;
            return manager.getRequest(key, url, model);
        },
        getMoreWebAPILogs(lastLogId) {
            var url = constants.api_urls.WEBAPI_LOG + "?$count=true&$orderby=Id desc";

            if (lastLogId !== null) {
                url = url + "&$filter=(Id lt " + lastLogId + ")";
            }

            var key = constants.actions.WEBAPI.GET_MORE_WEB_API_LOGS;
            return manager.getRequest(key, url, null);
        },
        getDefaultSuperFunds() {
            var url = constants.api_urls.DEFAULT_SUPER_FUNDS;
            var key = constants.actions.WEBAPI.GET_DEFAULT_SUPER_FUNDS;
            return manager.getRequest(key, url);
        },
        updateDefaultSuperFund(model) {
            var url = constants.api_urls.DEFAULT_SUPER_FUNDS;
            var key = constants.actions.WEBAPI.UPDATE_DEFAULT_SUPER_FUND;
            return manager.postRequest(key, url, model);
        },        
    },

    WORKFLOWS: {
        getWorkflowScopes() {
            var url = "{0}".format(constants.api_urls.GET_WORKFLOW_SCOPES);
            var key = constants.actions.WORKFLOWS.GET_WORKFLOW_SCOPES;
            return manager.getRequest(key, url);
        },
        getSingleWorkflowScope(id) {
            var url = "{0}".format(constants.api_urls.GET_SINGLE_WORKFLOW_SCOPE.format(id));
            var key = constants.actions.WORKFLOWS.GET_SINGLE_WORKFLOW_SCOPE;
            return manager.getRequest(key, url, id);
        },
        addWorkflow(model) {
            var url = constants.api_urls.WORKFLOW;
            var key = constants.actions.WORKFLOWS.ADD_WORKFLOW;
            return manager.postRequest(key, url, model);
        },
        updateWorkflow(model) {
            var url = constants.api_urls.WORKFLOW;
            var key = constants.actions.WORKFLOWS.UPDATE_WORKFLOW;
            return manager.postRequest(key, url, model);
        },
        getUserWorkflowScopes() {
            var url = "{0}".format(constants.api_urls.GET_USER_WORKFLOW_SCOPES);
            var key = constants.actions.WORKFLOWS.GET_USER_WORKFLOW_SCOPES;
            return manager.getRequest(key, url);
        },
    },

    WORKSPACE_MIGRATION: {  
        getMigrationUrl(model) {
            var url = "{0}/GetMigrationUrl".format(constants.api_urls.WORKSPACE_MIGRATION);
            var key = constants.actions.WORKSPACE_MIGRATION.GET_MIGRATION_URL;
            var params = model;
            return manager.postRequest(key, url, params);
        },
    },

    getWebApiConfigurations(model) {
        var url = constants.api_urls.WEBAPICONFIGURATION;
        var key = constants.actions.LOAD_WEBAPICONFIGURATIONS_FROM_SERVER;
        return manager.getRequest(key, url, model);
    },
    updateWebApiConfiguration(webApiConfiguration) {
        var url = constants.api_urls.WEBAPICONFIGURATION + '/' + webApiConfiguration.Id;
        var key = constants.actions.UPDATE_WEBAPICONFIGURATION;
        var params = webApiConfiguration;
        return manager.patchRequest(key, url, params);
    },
    removeWebApiConfiguration(id) {
        var url = constants.api_urls.WEBAPICONFIGURATION + '/' + id;
        var key = constants.actions.REMOVE_WEBAPICONFIGURATION;
        return manager.delRequest(key, url, id);
    },
    addWebApiConfiguration(webApiConfiguration) {
        var url = constants.api_urls.WEBAPICONFIGURATION;
        var key = constants.actions.ADD_WEBAPICONFIGURATION;
        var params = webApiConfiguration;
        return manager.postRequest(key, url, params);
    },
    syncWebApiData(apiKey) {
        var url = constants.api_urls.WEBAPI + '/' + apiKey;
        var key = constants.actions.WEBAPI.SYNC_WEBAPI_DATA;
        return manager.postRequest(key, url, apiKey);
    },
    syncAllWebApiData(apiKey) {
        var url = constants.api_urls.WEBAPI + '/SyncAllFromPayrollToTng' + '/' + apiKey;
        var key = constants.actions.WEBAPI.SYNC_ALL_WEBAPI_DATA;
        return manager.postRequest(key, url, apiKey);
    },
    syncLeaveWebApiData(apiKey) {
        var url = constants.api_urls.WEBAPI + '/SyncLeaveBalance' + '/' + apiKey;
        var key = constants.actions.WEBAPI.SYNC_LEAVE_WEBAPI_DATA;
        return manager.postRequest(key, url, apiKey);
    },
    getMcsVersions() {
        var url = constants.api_urls.MCS_VERSIONS;
        var key = constants.actions.GET_MCS_VERSIONS;
        return manager.getRequest(key, url);
    },
    syncInitialSetup(apikey) {
        var url = constants.api_urls.WEBAPI + '/SyncInitialSetupFromPayrollToTng/?WebApiKey=' + apikey;;
        var key = constants.actions.WEBAPI.SYNC_WEBAPI_TEAMS;
        return manager.postRequest(key, url);
    },
    GetDefaultMcsVersion() {
        var url = constants.api_urls.DEFAULT_MCS_VERSION;
        var key = constants.actions.GET_DEFAULT_MCS_VERSION;
        return manager.getRequest(key, url);
    },
    getConfigurationValues() {
        var url = constants.api_urls.CONFIGURATION + '/GetConfigurationValues';
        var key = constants.actions.GET_CONFIGURATION_VALUES;
        return manager.getRequest(key, url);
    },
    updateCompanyName(configKey, value) {
        var url = constants.api_urls.CONFIGURATION;
        var key = constants.actions.UPDATE_COMPANY_NAME;
        var params = {
            Key : configKey,
            Value: value,
        };
        return manager.putRequest(key, url, params);
    },
    updateMFAEnableNewMember(configKey, value) {
        var url = constants.api_urls.CONFIGURATION;
        var key = constants.actions.UPDATE_MFA_ENABLE_NEW_MEMBER;
        var params = {
            Key : configKey,
            Value: value,
        };
        return manager.putRequest(key, url, params);        
    },
};

export default Api;
