'use strict';

import appConstants from 'constants/appConstants';
import storageUtils from "services/storageUtils";

export default class EvoButtonManager {
    constructor() {
        this.isAccessIdUser;
        this.viewState;
    }
    
    initialise() {
        this.setIsAccessIdUser();

        if (this.isAccessIdUser) {
            this.updateView();
            
            const accessButtonLauncherScript = document.querySelector("#accessButtonLauncherScript");
            accessButtonLauncherScript.addEventListener("load", this.accessButtonLauncherScriptLoadedHandler());  
        }
    }

    accessButtonLauncherScriptLoadedHandler() {
        const accessButtonLauncherScript = document.querySelector("#accessButtonLauncherScript");
        accessButtonLauncherScript.removeEventListener("load", accessButtonLauncherScript);
        
        this.renderAccessButton();                
    }          

    renderAccessButton() {
        window.initialiseAccessButton({
            environment: appConstants.accessButtonEnvironment,
            view: this.viewState,
            positions: {
                parentElement: window.innerWidth < 988 ? "#evo-mobile-container" : "#evo-container",
                mobile: {
                    left: "0",
                    right: "0",
                },
            },
            getToken: () => this.getToken(),          
            intercomChatEnabled: true,
        });
    }

    async getToken() {
        var currentUser = storageUtils.getUserFromStorage();
        const accessToken = currentUser?.access_token;
        const idToken = currentUser?.id_token;

        const email = this.getEmail();

        return fetch(appConstants.api_urls.EVO_BUTTON_TOKEN + email, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
            },
            body: JSON.stringify(idToken)
        });
    }

    getEmail() {
        var currentUser = storageUtils.getUserFromStorage();
        var email = currentUser?.profile?.email ? currentUser.profile.email : null;
        return email;
    }

    resize() {
        if (this.isAccessIdUser) {
            this.updateView();
            this.renderAccessButton();
        }
    }

    updateView() {
        const newViewState = window.innerWidth < 988 ? "mobile" : "desktop";
            
        if (newViewState !== this.viewState) {
            this.viewState = newViewState;

            // hack here to clear any previous evo buttons from the parent eleme+nt, because it won't write to a different parent if a button is existing

            const desktopElement = document.getElementById("evo-container");
            if (desktopElement) {
                desktopElement.innerHTML = "";
            }

            const mobileElement = document.getElementById("evo-mobile-container");
            if (mobileElement) {
                mobileElement.innerHTML = "";
            }
        }
    }
    
    getIsAccessIdUser() {
        return this.isAccessIdUser;
    }
    
    setIsAccessIdUser() {
        this.isAccessIdUser = (storageUtils.getUserIdPFromStorage() === appConstants.ENUMS.IDENTITY_PROVIDER.ACCESS) ? true : false;
    }   
}
