'use strict';
import appConstants from 'constants/appConstants';

const storageUtils = {
    getUserIdPFromStorage: () => {
        return storageUtils.getValueFromStorage(appConstants.STORAGE.USER_IDP);
    },

    setUserIdPToStorage: (value) => {
        storageUtils.setValueToStorage(appConstants.STORAGE.USER_IDP, value);
    },

    getUserFromStorage: () => {
        return storageUtils.getValueFromStorage(appConstants.STORAGE.USER);
    },

    setUserToStorage: (value) => {
        storageUtils.setValueToStorage(appConstants.STORAGE.USER, value);
    },

    getValueFromStorage: (key) => {
        switch (appConstants.auth.OIDC_CACHE_LOCATION) {
            case 'localStorage':
                return JSON.parse(localStorage.getItem(key) || 'null');
            case 'sessionStorage':
                return JSON.parse(sessionStorage.getItem(key) || 'null');
            default:
                return JSON.parse(sessionStorage.getItem(key) || 'null');
        }
    },

    setValueToStorage: (key, value) => {
        switch (appConstants.auth.OIDC_CACHE_LOCATION) {
            case 'localStorage':
                localStorage.setItem(key, JSON.stringify(value));
            case 'sessionStorage':
                sessionStorage.setItem(key, JSON.stringify(value));
            default:
                sessionStorage.setItem(key, JSON.stringify(value));
        }        
    },  
  
    clearStorage: () => {
        switch (appConstants.auth.OIDC_CACHE_LOCATION) {
            case 'localStorage':
                localStorage.clear();
            case 'sessionStorage':
                sessionStorage.clear();
            default:
                sessionStorage.clear();
        }        
    },
};

export default storageUtils;